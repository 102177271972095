.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 5% 10% 5% 10%;
  background-color: blue;
  color: pink;
}

.contact-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 5% 10% 5% 10%;
}

.linked-in {
  // text-decoration: none;
  color: black;
  font-size: 1vw;
}

.linked-in-ctn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.LI-logo {
  margin: 0 10px;

}