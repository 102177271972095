.photo-main {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.mugshot {
  margin: 3rem 0px;
  width: 90%;
  height: 80%;
}
