.intro-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 5% 10% 5% 10%; 
}

.intro-header {
  font-size: 4.4vw;
  font-weight: 600;
  margin: 5% 0%; 
}

.intro-p1 {
    // font-family: 'Medium';
    font-size: 2vw;
    color: #111111;
    line-height: 140%;
    margin-bottom: 6vmin;
    overflow: hidden;
    font-weight: bold;
;
}

.intro-p2 {
    // font-family: 'Semibold';
    font-size: 2vw;
    color: #111111;
    line-height: 140%;
    margin-bottom: 6vmin;
    overflow: hidden;

}

.intro-p3 {
  // font-family: 'Semibold';
  font-size: 2vw;
  color: #111111;
  line-height: 140%;
  margin-bottom: 6vmin;
  overflow: hidden;

}

.intro-p4 {
  // font-family: 'Semibold';
  font-size: 2vw;
  color: #111111;
  line-height: 140%;
  margin-bottom: 6vmin;
  overflow: hidden;
  // margin: 5vmin 30vmin 188px 30vmin;
}

@media only screen and (max-width: 750px) {
  .intro-p1 {
    font-size: 4vw;
  }

  .intro-p2 {
    font-size: 4vw;
  }

  .intro-p3 {
    font-size: 4vw;
  }

  .intro-p4 {
    font-size: 4vw;
  }
}