body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.body {
  margin: 0 5%;
  font-size: 16px;
}
.name {
  font-size: 2vw;
  line-height: 80%;
  font-weight: 600;
  color: #111111;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 5% 10% 5% 10%; }

.sub-header {
  font-size: 4.4vw;
  line-height: 120%;
  color: #111111;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin: 5% 10% 5% 10%; }

@media only screen and (max-width: 750px) {
  .name {
    font-size: 4vw; } }

.photo-main {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.mugshot {
  margin: 3rem 0px;
  width: 90%;
  height: 80%; }

.intro-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 5% 10% 5% 10%; }

.intro-header {
  font-size: 4.4vw;
  font-weight: 600;
  margin: 5% 0%; }

.intro-p1 {
  font-size: 2vw;
  color: #111111;
  line-height: 140%;
  margin-bottom: 6vmin;
  overflow: hidden;
  font-weight: bold; }

.intro-p2 {
  font-size: 2vw;
  color: #111111;
  line-height: 140%;
  margin-bottom: 6vmin;
  overflow: hidden; }

.intro-p3 {
  font-size: 2vw;
  color: #111111;
  line-height: 140%;
  margin-bottom: 6vmin;
  overflow: hidden; }

.intro-p4 {
  font-size: 2vw;
  color: #111111;
  line-height: 140%;
  margin-bottom: 6vmin;
  overflow: hidden; }

@media only screen and (max-width: 750px) {
  .intro-p1 {
    font-size: 4vw; }
  .intro-p2 {
    font-size: 4vw; }
  .intro-p3 {
    font-size: 4vw; }
  .intro-p4 {
    font-size: 4vw; } }

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 5% 10% 5% 10%;
  background-color: blue;
  color: pink; }

.contact-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 5% 10% 5% 10%; }

.linked-in {
  color: black;
  font-size: 1vw; }

.linked-in-ctn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.LI-logo {
  margin: 0 10px; }

